var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.error
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.error) + "\n            "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.camera !== "off",
              expression: "camera !== 'off'",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c(
            "div",
            [
              _c(
                "qrcode-stream",
                {
                  attrs: { camera: _vm.camera },
                  on: { decode: _vm.onDecode, init: _vm.onInit },
                },
                [
                  _vm.loading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-content-center",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-lg",
                              attrs: { type: "button", disabled: "" },
                            },
                            [
                              _c("span", {
                                staticClass: "spinner-border spinner-border-sm",
                                attrs: {
                                  role: "status",
                                  "aria-hidden": "true",
                                },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("trans")("messages.loading")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.camera === "off",
              expression: "camera === 'off'",
            },
          ],
          staticClass: "col",
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm._f("trans")("messages.attendee")) + ": "),
            _c("strong", [_vm._v(_vm._s(_vm.attendee.name))]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm._f("trans")("messages.identity_document")) + ": "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.attendee.identity_document))]),
          ]),
          _vm._v(" "),
          _vm.attendee.accessed
            ? _c("p", [
                _vm._v(_vm._s(_vm._f("trans")("messages.last_access")) + ": "),
                _c("strong", [_vm._v(_vm._s(_vm.attendee.accessed_at))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.attendee.accessed
            ? _c("p", [
                _vm._v(
                  _vm._s(_vm._f("trans")("messages.number_of_accesses")) + ": "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.attendee.access_count))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-lg btn-block",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.registerAccess(_vm.attendeeId)
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-fw fa-check" }),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm._f("trans")("messages.mark_as_used"))),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary btn-lg btn-block",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.restart()
                },
              },
            },
            [
              _c("span", { staticClass: "font-bold" }, [
                _c("i", { staticClass: "fas fa-fw fa-arrow-left" }),
                _vm._v(" " + _vm._s(_vm._f("trans")("messages.back"))),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }