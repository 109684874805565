<template>
    <div class="container">
        <div v-if="error" class="row">
            <div class="col-12">
                <div class="alert alert-danger" role="alert">
                    {{ error }}
                </div>
            </div>
        </div>
        <div class="row">
            <div v-show="camera !== 'off'" class="col-12">
                <div>
                    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                        <div v-if="loading" class="d-flex justify-content-center align-content-center">
                            <button class="btn btn-primary btn-lg" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ 'messages.loading' | trans }}
                            </button>
                        </div>
                    </qrcode-stream>
                </div>
            </div>
            <div v-show="camera === 'off'" class="col">
                <p>{{ 'messages.attendee' | trans }}: <strong>{{ attendee.name }}</strong></p>
                <p>{{ 'messages.identity_document' | trans }}: <strong>{{ attendee.identity_document }}</strong></p>
                <p v-if="attendee.accessed">{{ 'messages.last_access' | trans }}: <strong>{{ attendee.accessed_at }}</strong></p>
                <p v-if="attendee.accessed">{{ 'messages.number_of_accesses' | trans }}: <strong>{{ attendee.access_count }}</strong></p>
                <div class="mb-3"></div>
                <button type="button" @click="registerAccess(attendeeId)" class="btn btn-success btn-lg btn-block">
                    <i class="fas fa-fw fa-check"></i><br>
                    <span class="font-bold">{{ 'messages.mark_as_used' | trans }}</span>
                </button>
                <button type="button" @click="restart()" class="btn btn-outline-secondary btn-lg btn-block">
                    <span class="font-bold"><i class="fas fa-fw fa-arrow-left"></i> {{ 'messages.back' | trans }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        routes: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            attendee: {},
            attendeeId: '',
            camera: 'auto',
            error: '',
            loading: false,
        }
    },
    methods: {
        registerAccess(attendeeId) {
            axios.post(this.routes['registerAccess'],
                {
                    attendee: attendeeId
                })
                .then(response => {
                    console.log(response);
                    showMessage(['success', response.data.data.message, '10', 'top-right']);
                    this.restart();
                })
                .catch(error => {
                    console.log(error);
                    showMessage(['error', error.message, '10', 'top-right']);
                    this.restart();
                });
        },
        restart() {
            this.attendee = {};
            this.attendeeId = '';
            this.camera = 'auto';
        },
        async onDecode(attendeeId) {
            if (!attendeeId) {
                return;
            }
            this.attendeeId = attendeeId;
            axios.post(this.routes['checkAccess'],
                {
                    attendee: attendeeId
                })
                .then(response => {
                    console.log(response);
                    this.attendee = response.data.data;
                    this.camera = 'off';
                })
                .catch(error => {
                    console.log(error);
                    showMessage(['error', error.message, '10', 'top-right']);
                    this.restart();
                });
        },
        async onInit(promise) {
            this.loading = true;
            try {
                await promise
            } catch (error) {
                //console.error(error);
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
