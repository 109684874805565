$(function () {
    $.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});

    let $body = $("body");

    $body.on("click", ".js-body-loading", function () {
        bodyShowLoading();
    });

    $body.on("click", ".js-button-loading", function () {
        buttonShowLoading($(this), false);
    });

    $body.on("click", "[data-href]", function (e) {
        e.stopPropagation();
        window.document.location = $(this).data("href");
    });

    $body.on("click", ".js-confirm-dialog", function (e) {
        e.preventDefault();
        let message = $(this).data("message");
        let route = $(this).data("route");
        let form = $(this).data("form");
        //If route is define redirect to route
        if (route !== undefined) {
            showConfirmDialog(message, function () {
                window.document.location = route;
            });
        }
        //If form is defined submit form
        else if (form !== undefined) {
            showConfirmDialog($(this).data("message"), function () {
                bodyShowLoading();
                $(form).submit();
                //TODO mirar como poner el swal de Procesando
            });
        }
    });

    $body.on("click", ".js-confirm-submit", function (e) {
        e.preventDefault();
        let message = $(this).data("message");
        showConfirmDialog($(this).data("message"), function () {
            bodyShowLoading();
            $("form").submit();
        });
    });

    $body.on("click", ".js-confirm-text", function(e){
        e.preventDefault();
        let title = $(this).data("title");
        let route = $(this).data("route");
        let text = $(this).data("text");
        let type = $(this).data("type") ?? 'info';
        let config = {
            animation: false,
            title: title,
            type: type,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: Lang.get('messages.accept'),
            cancelButtonText: Lang.get('messages.cancel'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-primary ml-4',
            input: 'textarea',
            inputValue: text,
            width: '800px',
            preConfirm: function(result) {
                return axios.post(route, {
                    text: result
                }).then(function (response) {
                    window.location.reload(true);
                });
            }
        };
        swal(config);
    });

    $body.on("click", ".js-report-dialog", function (e) {
        e.preventDefault();

        const $this = $(this);
        const params = {
            title: $this.data('title'),
            message: $this.data('message'),
            route: $this.data('route')
        };

        showReportDialog(params);
    });

    $('[data-toggle="tooltip"]').tooltip();

    $(".js-select2").each(function () {
        initSelect2($(this));
    });

    $(".js-select2-ajax").each(function () {
        initSelect2($(this), true);
    });

    $('.js-selectpicker').selectpicker({
        'liveSearch': true,
    });

    $('.js-selectpicker-single').selectpicker({
        'liveSearch': true,
        'maxOptions': 1,
    });

    $('.js-date-field').datetimepicker({
        useCurrent: false,
        format: localization.date_format,
        locale: localization.language_code2,
    });

    $('.js-datatable').DataTable({
        lengthMenu: [
            [25, 50, 100, -1],
            [25, 50, 100, Lang.get('messages.all_pages')]
        ],
        info: false,
        dom: '<"top"<"left">f<"clear">>rt<"bottom"lp<"clear">>',
        order: [],
        language: { url: '/js/vendor/datatables/locales/' + localization.language_code5 + '.json' },
    });

    //Fix dropdown buttons overflow in table responsive
    $('.table-responsive')
        .on('shown.bs.dropdown', function (e) {
            let t = $(this),
                m = $(e.target).find('.dropdown-menu'),
                tb = t.offset().top + t.height(),
                mb = m.offset().top + m.outerHeight(true),
                d = 20; // Space for shadow + scrollbar.
            if (t[0].scrollWidth > t.innerWidth()) {
                if (mb + d > tb) {
                    t.css('padding-bottom', ((mb + d) - tb));
                }
            } else {
                t.css('overflow', 'visible');
            }
        })
        .on('hidden.bs.dropdown', function () {
            $(this).css({'padding-bottom': '', 'overflow': ''});
        });
});

window.bodyShowLoading = function () {
    swal({
        animation: false,
        title: Lang.get('messages.processing'),
        showConfirmButton: false,
        allowOutsideClick: false,
    });
    swal.showLoading();
};

window.bodyHideLoading = function () {
    swal.close();
};

window.buttonShowLoading = function (btn, changeWidth) {
    if (changeWidth) {
        let width = btn.width();
        btn.width(width);
    }
    btn.addClass('disabled');
    btn.html('<i class="fa fa-spinner fa-spin fa-fw fa-custom"></i>');
};

window.buttonHideLoading = function (btn, text) {
    btn.removeClass('disabled');
    btn.html(text);
};

window.checkSession = function (route) {
    setInterval(function() {
        axios.get(route)
            .then(function (response) {
                //console.log(response.data.result);
                if (response.data.result === 'error')
                    window.location.replace(response.data.url);
            });
    }, 1000 * 60); // 1 min
};

window.initSelect2 = function (object, ajax) {
    ajax = ajax || false;
    let config = {
        language: localization.locale_code2,
        placeholder: Lang.get('messages.select_an_option'),
        allowClear: true,
    };
    if (ajax) {
        config = {...config,
            multiple: false,
            minimumInputLength: 2,
            ajax: {
                url: undefined,
                dataType: 'json',
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
                cache: true,
                delay: 250
            }
        };
    }
    object.select2(config);
};

window.initDatePicker = function (object) {
    console.log(object);
    object.datetimepicker({
        useCurrent: false,
        format: localization.date_format,
        locale: localization.language_code2,
    });
}

/**
 * Instructions
 * 1. type => [
 *     success,
 *     info,
 *     warning,
 *     error
 * ]
 * 2. message
 * 3. duration (in seconds) => 0 to infinite
 * 4. position => [
 *     top-right,
 *     bottom-right,
 *     bottom-left,
 *     top-left,
 *     top-full-width,
 *     bottom-full-width,
 *     top-center,
 *     bottom-center
 * ]
 */
window.showMessage = function ($params) {
    let type = $params[0] || 'success';
    let text = $params[1] || 'Ok';
    let duration = $params[2] || '5';
    let position = $params[3] || 'top-right';

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-" + position,
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": duration * 1000,
        "extendedTimeOut": duration * 1000,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    switch (type) {
        case 'success':
            toastr.success(text);
            break;
        case 'info':
            toastr.info(text);
            break;
        case 'warning':
            toastr.warning(text);
            break;
        case 'error':
            toastr.error(text);
            break;
    }
};

window.showConfirmDialog = function(message, callback) {
    swal({
        animation: false,
        title: message,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: Lang.get('messages.accept'),
        cancelButtonText: Lang.get('messages.cancel'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-primary ml-4',
        buttonsStyling: false
    }).then((result) => {
        if (result.value) callback();
    });
};

window.showReportDialog = function(params) {
    swal({
        animation: false,
        title: params.title,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: Lang.get('messages.accept'),
        cancelButtonText: Lang.get('messages.cancel'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-primary ml-4',
        buttonsStyling: false,
        html: $($('#template-report-dialog').html()),
        showLoaderOnConfirm: true,
        onOpen: (dialog) => {
            $('#dialog-message').html(params.message);
        },
        preConfirm: () => {
            downloadFile(params.route, {
                month: $('#dialog-month').val(),
                year: $('#dialog-year').val()
            });
        }
    });
};

/**
 * @param {string} location
 * @param {Object} data
 * @param {string} [method=post]
 */
function submitData(location, data, method) {

    method = method || 'post';

    const $form = $('<form></form>');
    $form.attr('method', method);
    $form.attr('action', location);

    for (const [key, value] of Object.entries(data)) {

        const $field = $('<input/>');
        $field.attr('type', 'hidden');
        $field.attr('name', key);
        $field.attr('value', value);

        $form.append($field);
    }
    $(document.body).append($form);
    $form.submit();
}

/**
 * @param {string} location
 * @param {Object} [data]
 * @param {string} [method]
 */
function downloadFile(location, data, method) {

    bodyShowLoading();

    const config = {
        method: method || ((data) ? 'POST' : 'GET')
    };

    if (data) {
        config.body = new FormData();
        for (const [key, value] of Object.entries(data)) {
            config.body.append(key, value);
        }
    }

    fetch(location, config)
        .then(function (response) {

            if (response.ok) {
                return saveFile(response);
            }

            getErrorMessage(response)
                .then(function (message) {
                    showMessage(['error', message ]);
                });
        })
        .then(function () {
            bodyHideLoading();
        });
}

/**
 * @param {Response} response
 * @returns {Promise}
 */
async function saveFile(response) {

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const $a = document.createElement('a');

    $a.style.display = 'none';
    $a.href = url;
    $a.download = getFileName(response.headers);
    document.body.appendChild($a);
    $a.click();
    window.URL.revokeObjectURL(url);
}

/**
 * @param {Headers} headers
 * @return {string}
 */
function getFileName(headers) {

    const disposition = headers.get('Content-Disposition');

    if (!disposition) {
        return '';
    }

    if (disposition.indexOf('attachment') === -1
        && disposition.indexOf('inline') === -1) {
        return '';
    }

    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(disposition);

    if (!matches || !matches[1]) {
        return '';
    }

    return matches[1].replace(/['"]/g, '');
}

/**
 * @param {Response} response
 * @returns {Promise<string>}
 */
async function getErrorMessage(response) {

    const data = await response.json();

    if (response.status === 400) {
        return data.message;
    }

    if (response.status === 422) {
        const errors = Object.values(data.errors);

        if (!errors) {
            return Lang.get('messages.form_errors_found');
        }

        if (errors.length === 1) {
            return errors[0];
        } else {
            let msg = '';
            for (const error of errors) {
                msg += error + '<br>';
            }
            return msg;
        }
    }

    return Lang.get('messages.form_submitted_ko');
}
